// import $ from 'jquery'
// import { ImageComparison } from 'image-comparison'
//
// $(document).ready(function() {
//   dhsv_vc_comparison_slider()
// })
//
// function dhsv_vc_comparison_slider() {
//   console.log(ImageComparison) // eslint-disable-line
//   console.log('comparison_slider') // eslint-disable-line
//
//   const $containerSelector = $('.js-comparison-slider-container')
//
//   new ImageComparison({
//     container: $containerSelector,
//     startPosition: 70,
//     data: [
//       {
//         // image: images[0],
//         label: 'before',
//       },
//       {
//         // image: images[1],
//         label: 'after',
//       },
//     ],
//   })
// }
