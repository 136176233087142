import $ from 'jquery'

$(document).ready(function () {
  dhsv_vc_company_teaser_list()
})

function dhsv_vc_company_teaser_list() {
  $('.dhsv_vc_company_teaser_list').each(function () {
    var loadMoreButton = document.getElementById('conpany-list-load-more')
    var hiddenItems = document.querySelectorAll('.item-wrapper.hidden')
    var buttonWrapper = document.getElementById('more-button-wrapper')

    loadMoreButton.addEventListener('click', function () {
      hiddenItems.forEach(function (item) {
        item.classList.remove('hidden')
      })
      loadMoreButton.style.display = 'none'
      buttonWrapper.classList.add('hidden')
    })
  })
}
